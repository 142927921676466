
.Form {
  width: 500px;
  margin: 50px auto;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

input.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid gray;
}

button.btn-primary {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: rgb(24, 114, 233);
  color: white;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}
