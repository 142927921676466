
.QRScanner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*#scanner-container {
  width: 500px;
  height: 500px;
  border: 1px solid rgb(15, 102, 184);
}*/

#scanner-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: 1px solid rgb(15, 102, 184);
}